@use '@angular/material' as mat;
@import "zen-colors";

.mat-mdc-button, .mat-mdc-raised-button, .mat-mdc-icon-button, .mat-mdc-stroked-button, .mat-mdc-flat-button, .mat-mdc-fab, .mat-mdc-mini-fab {
  //font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: 1.4rem !important;
  font-weight: 500;

  .mat-ripple.mat-mdc-button-ripple {
    display: none !important;
  }
}

.mat-mdc-outlined-button:not(.mat-mdc-raised-button[disabled]) {
  border-color: var(--textColorLight55);
  border-radius: 0.5rem;

  &.view-stroked {
    width: 100%;
    padding: 0.5rem 0;
    border-radius: 2rem;

    &:active {

    }

    .mdc-button__label {
      display: flex;
      align-items: center;
    }

    /* To disable ripple */
    .mdc-button__ripple {
      opacity: 0 !important;
    }
  }
}

.mat-mdc-button:not(.mat-mdc-raised-button):not(mdc-button--raised):not(.mat-mdc-flat-button):not(mdc-button--flat) .mdc-button__label {
  color: $textColorLight;
}

.mdc-button--raised {
  color: white !important;
  height: 4.6rem !important;
}

.mat-mdc-icon-button {

  &.with-text {
    background: white;
    padding: 0.5rem 2rem 0.5rem 1rem !important;
    border-radius: 0.5rem;
    border: 0.1rem solid $textColorLight_2;
    text-align: left;
    width: 100% !important;
    height: 5rem !important;
    line-height: 2;
    font-weight: 400;
    display: flex;
    align-items: center;

    .btn-label {
      font-size: 1.4rem;
    }

    i.color-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info-icon {
      cursor: pointer;
      margin-left: 0.5rem;
      font-size: 1.25rem;
      z-index: 1; // To support info popper
    }

    &.default-state-btn {
      display: inline-flex;
      border-color: $textColorLight;
      font-size: 1rem !important;
      cursor: default;
      height: 2.5rem !important;
      width: -moz-fit-content !important;
      width: fit-content !important;
      padding: 0.25rem 0.5rem !important;
      vertical-align: middle;
    }

    &.selected {
      border: 0.2rem solid $textColorLight;
      font-weight: 600;
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    &.small {
      height: 4.5rem;
      font-size: 1.2rem;
    }

    &.no-border {
      border-width: 0;
    }
  }

  &.sm {
    height: 1.7142rem !important;
    width: 1.7142rem !important;
  }
}

.mat-mdc-button {
  min-width: auto !important;
}

.mat-mdc-unelevated-button, .mdc-button--raised {
  padding: 0.5rem 1.5rem !important;

  &.mat-warn {
    background: $danger_1;
    .mdc-button__label {
      color: white;
    }
  }
  &.mat-warning {
    background: $warn_2;
    .mdc-button__label {
      color: white;
    }
  }
  &.mat-info {
    background: $info_1;
    .mdc-button__label {
      color: white;
    }
  }

  &.mat-white {
    background: white;
    border: 0.5px solid $textColorDark;
    .mdc-button__label {
      color: $textColorDark;
    }
  }

  &.mat-blue {
    background-color: $blue_1 !important;
    .mdc-button__label {
      color: white;
    }
  }

  &.mat-green {
    background-color: $success_1 !important;
    .mdc-button__label {
      color: white;
    }
  }

  // Button border only design
  &.border-only {
    background: transparent !important;

    &.mat-accent, &.mat-accent.cdk-focused.cdk-mouse-focused {
      background: transparent;
      border: 1px solid $secondaryClr;
      padding: 0.4rem 1.5rem !important;
      .mdc-button__label {
        color: $secondaryClr;
      }
    }

    &.mat-warn {
      border: 1px solid $danger_1;
      .mdc-button__label {
        color: $danger_1;
      }
    }

    &.mat-warning {
      border: 1px solid $warn_2;
      .mdc-button__label {
        color: $warn_2;
      }
    }

    &.mat-info {
      border: 1px solid $info_1;
      .mdc-button__label {
        color: $info_1;
      }
    }

    &.mat-green {
      border: 1px solid $success_1;
      .mdc-button__label {
        color: $success_1;
      }
    }

    &:hover:not(.mat-mdc-raised-button[disabled]) {
      .mdc-button__label {
        color: white;
      }

      &.mat-accent {
        background: $secondaryClr !important;
      }

      &.mat-warn {
        background: $danger_1 !important;
      }

      &.mat-warning {
        background: $warn_2 !important;
      }

      &.mat-info {
        background: $info_1 !important;
      }

      &.mat-green {
        background: $success_1 !important;
      }
    }
  }

  &.lg {
    padding: 1rem 4rem !important;
  }
}


/* Button disabled state */
.mat-primary.mat-mdc-raised-button[disabled]:not(.mat-mdc-button):not(.border-only) {
  background: $primaryClr !important;
}

.mat-accent.mat-mdc-raised-button[disabled]:not(.mat-mdc-button):not(.border-only) {
  background: $secondaryClr !important;
}

.mat-warn.mat-mdc-raised-button[disabled]:not(.mat-mdc-button):not(.border-only) {
  background: $danger_1 !important;
}

.mat-warning.mat-mdc-raised-button[disabled]:not(.mat-mdc-button):not(.border-only) {
  background: $warn_2 !important;
}
// Border only disable state
.mat-mdc-raised-button[disabled].border-only:not(.mat-mdc-button):not(.mat-mdc-icon-button) {
  opacity: 1;
  background: transparent;
  border: 1px solid $textColorLight_2;
  .mdc-button__label {
    color: $textColorLight_2;
  }
}


.mat-mdc-button, .mat-mdc-unelevated-button, .mdc-button--raised {
  box-shadow: none !important;
  border-radius: 0.5rem !important;

  /* Button hover state */
  &:hover:not(.mat-mdc-raised-button[disabled]), &.cdk-focused.cdk-mouse-focused {
    outline: inherit !important; // to fix focused btn hide issue.

    &.mat-primary {
      background-color: var(--primaryColorDark5);
    }

    &.mat-accent {
      background-color: var(--secondaryColorDark5);
    }
  }

  .mdc-button__label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
  }

  &.md {
    padding: 0.5rem;
    min-width: 12rem;

    span.title {
      padding: 0 1rem 0 1.5rem;
    }

    i:nth-child(1) {
      position: absolute;
      left: 1.125rem;
    }
  }

  /* Button with two icons (left and right) */
  &.split-btn {
    padding: 0rem 1rem;
    min-width: 14rem;

    .mdc-button__label {
      justify-content: space-between;

      i:nth-child(3) {
        border-left: 1px solid;
        padding: 0.95rem 0 0.95rem 0.75rem;
        margin-left: 1rem;
      }
    }
  }

  /* To avoid default cdkFocusInitial */
  .mat-mdc-focus-indicator {
    opacity: 0 !important;
  }

  // To hide btn text
  @media (max-width: 900px) {
    &.mobile-icon-only-fixed, &.mobile-icon-only {
      padding: 1rem 1.2rem 1rem 2.2rem !important;
      min-width: auto;
      max-width: 4.25rem;
      z-index: 20;

      .mdc-button__label span {
        display: none;
      }
    }
  }

  @media (max-width: 780px) {
    &.mobile-icon-only-fixed {
      position: fixed;
      bottom: 5rem;
      right: 4.5rem;
    }
  }
}


/** Fab buttons */
.fab-btn {
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0;
  display: flex;
  border: none;
  align-items: center;
  background: var(--primaryColorLight35) !important;
  height: 3.5rem;
  width: 3.5rem;
  justify-content: center;

  .button-label {
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 600;
    color: $textColorDark;
  }

  &.circle {
    padding: 1.2rem !important;
    width: 4.75rem !important;
    height: 4.75rem !important;
    border-radius: 50% !important;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}


.beta {
  border-radius: 0.5rem;
  background: $blue_1;
  padding: 0.125rem 0.5rem;
  color: white;
}

.beta-v2, .legacy-v1 {
  border-radius: 1rem;

  padding: 0.125rem 0.75rem;

  max-height: 1.75rem;
  display: flex;
  align-items: center;
}

.beta-v2 {
  background: $info_1;
  color: white;
}

.legacy-v1 {
  background: $textColorLight_3;
  color: $textColor;
}

.filter-icon-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.filter-icon-button {
  z-index: 3;
  width: 4.2rem;
  height: 3.2rem;
  justify-content: center;

  border: none;
  font-size: 1.5rem;
  font-weight: 500;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  position: absolute;
  right: 2rem;
  color: $textColorLight;
  background-color: white;
  &:hover {
    color: white;
    background-color: $secondaryClr;
  }
  &.active {
    color: white;
    background-color: $secondaryClr;
  }
  top: 2rem;
  &.filter-header-button {
    position: relative;
    float: right;
    right: 0;
    margin: 0;
    top: .5rem;
  }
  display: flex;
  align-items: center;
  border-radius: .8rem;
}


@media screen and (max-width: 992px) {
  .filter-icon-button-wrapper {
    margin-bottom: 5rem;
    .filter-icon-button.filter-icon-details-page {
      top: 1rem;
    }
  }
}

.zero-index {
  z-index: 0;
}

.filter-button {
  z-index: 2;
  /* Filter */
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  color: $blue_1 !important;
  font-weight: 500;

  /* identical to box height */
  .mdc-button__label {
    color: $blue_1 !important;
    font-weight: 500;
    font-size: 1.2rem !important;
  }

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  display: flex;
  align-items: center;
}

/* Button hover state */
.mat-mdc-unelevated-button.mat-primary, .mat-flat-button.mat-accent, .mat-flat-button.mat-warn, .mat-flat-button.mat-warning, .mat-flat-button,
.mdc-button--raised.mat-primary, .mat-raised-button.mat-accent, .mat-raised-button.mat-warn, .mat-raised-button.mat-warning, .mat-raised-button,
.mat-mdc-fab.mat-primary, .mat-fab.mat-accent, .mat-fab.mat-warn, .mat-fab.mat-warning, .mat-fab,
.mat-mdc-mini-fab.mat-primary, .mat-mini-fab.mat-accent, .mat-mini-fab.mat-warn, .mat-mini-fab.mat-warning, .mat-mini-fab {

  /* Button loading state */
  .mat-mdc-progress-spinner {
    display: none;
  }

  &.loading {
    .mat-mdc-progress-spinner {
      display: block;
      margin-left: 1rem;

      svg circle {
        stroke: white !important;
      }
    }
  }
}

/* Zen chip button */
.zen-chip-btn, .zen-chip-btn.mat-mdc-button {
  padding: 0 !important;

  .mdc-button__label {
    border: 1px solid $textColor;
    padding: 0rem 1.25rem 0rem 1rem;
    border-radius: 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    height: 2.5rem;
    color: $textColor;

    i {
      font-size: 1.6rem;
    }
  }

  &.mat-green {

    .mdc-button__label {
      border: 1px solid $success_4;
      color: $success_1;
      background: $success_4;
    }

    &:hover {
      background: transparent !important;
    }
  }

  &.mat-accent {

    .mdc-button__label {
      border: 1px solid var(--secondaryColorLight35);
      color: $secondaryClr;
      background: var(--secondaryColorLight35);
    }

    &:hover {
      background: transparent !important;
    }
  }
}

/* Zen split btn */
.zen-split-btn {
  border: 1px solid !important;
  border-color: $textColorLight_3;
  background: transparent !important;
  color: $textColorLight_3 !important;
  height: 4.6rem;
  min-width: 13.5rem !important;

  &:not(.default) {
    padding: 0 !important;
  }

  &.default {
    background: $textColorLight_4 !important;
    color: $textColorLight !important;
  }

  &.mat-primary {
    border-color: $primaryClr;
    color: $primaryClr !important;

    .mdc-button__label {
      .left-sec {
        border-right: 1px solid $primaryClr;
        padding: 0.5rem 1rem;
        position: relative;
        top: -0.1rem;
      }

      .right-sec {
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
      }

      .split-btn-close-icon {
        color: $danger_1;
        margin-left: 1rem;
      }
    }
  }
}

// Disable ripple
.mat-mdc-icon-button, .mat-mdc-button, .mat-mdc-raised-button {
  .mat-mdc-button-persistent-ripple {
    display: none !important;
  }
}

.mat-mdc-raised-button {
  &.sm {
    height: 3.5rem !important;
  }
}

.mat-mdc-mini-fab.icon-btn {
  transition: all 0.3s;
  width: 2.75rem;
  height: 2.75rem;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
  --mat-fab-focus-state-layer-opacity: 0;

  &:not(.no-bg) {
    --mdc-fab-container-color: white;
    --mat-fab-foreground-color: #336AF8; // $blue_1
  }

  &.no-bg {
    --mdc-fab-container-color: none;
    --mat-fab-foreground-color: #878F97; // $textColor
    box-shadow: none !important;
  }

  &:hover {
    --mdc-fab-container-color: #336AF8; // $blue_1
    --mat-fab-foreground-color: white;
  }

  .mat-icon {
    line-height: 1.35;
  }
}
